/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-07 01:29:06
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-10 00:16:39
 * @Description: 请输入文件描述信息
 * @FilePath: \src\components\warehousePicker\api.js
 */

import http from "@/network/network.js";

// 获取仓库列表
export const getWarehouseList = (params) =>
  http.get("/v1/Warehouse/GetWarehouseList", params);

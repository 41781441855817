<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-09 10:45:15
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-10 09:26:58
 * @Description: 仓库选择器
 * @FilePath: \src\components\warehousePicker\index.vue
-->
<template>
  <van-popup
    v-model:show="showModule"
    position="right"
    closeable
    close-icon="close"
    :style="{ height: '100%', width: '100%' }"
  >
    <div class="kc-app">
      <van-nav-bar
        title="仓库选择器"
        left-arrow
        :fixed="true"
        :placeholder="true"
        :z-index="500"
        :safe-area-inset-top="true"
        @click-left="showModule = false"
      />

      <!-- 组织筛选 -->
      <van-field
        v-model="kor_name"
        v-if="!one_kor"
        is-link
        readonly
        label="组织"
        placeholder="选择组织"
        @click="showKorPicker = true"
      />

      <!-- 列表 -->
      <van-list
        v-model:loading="loading"
        v-model:error="error"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell
          v-for="item in warehouseList"
          :key="item.kwh_id"
          :title="item.name"
          is-link
          @click="pickItem(item)"
        />
      </van-list>

      <!-- 组织选择器 -->
      <organization-picker
        v-model:show="showKorPicker"
        v-model:kor_id="kor_id"
        v-model:kor_name="kor_name"
        v-model:one_kor="one_kor"
      ></organization-picker>
    </div>
  </van-popup>
</template>

<script>
import { getCurrentInstance, ref, watchEffect, watch } from "vue";
import { getWarehouseList } from "./api";
import organizationPicker from "@/components/organizationPicker";
export default {
  components: {
    organizationPicker,
  },
  // props 注册接受 自定义标签
  props: {
    show: Boolean,
    warehouse_id: String,
    warehouse_name: String,
  },

  // emits 注册 接受自定义标签 属性绑定的事件
  emits: ["update:show", "update:warehouse_id", "update:warehouse_name"],

  setup(props, context) {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;
    utils;
    const showModule = ref(false); //是否显示组件

    const one_kor = ref(false); //是否只有一个组织
    const showKorPicker = ref(false); //控制组织选择器是否显示
    const kor_id = ref(""); //组织ID
    const kor_name = ref(""); //组织名称

    const warehouseList = ref([]); //客户列表
    const loading = ref(false); //列表是否处于加载状态
    const error = ref(false); //列表是否加载失败
    const finished = ref(false); //列表是否完全加载完成
    const pageNo = ref(1); //当前页码

    //监听显示
    watchEffect(() => {
      showModule.value = props.show;
    });

    //监听显示2
    watchEffect(() => {
      context.emit("update:show", showModule.value);
    });

    //组织ID变化
    watch(
      () => kor_id.value,
      () => {
        pageNo.value = 1;
        warehouseList.value = [];
        onLoad();
      }
    );

    //确认选择
    const pickItem = (item) => {
      context.emit("update:warehouse_id", item.kwh_id);
      context.emit("update:warehouse_name", item.name);
      showModule.value = false;
    };

    //触发列表加载
    const onLoad = () => {
      getWarehouseList({
        pageNo: pageNo.value,
        pageSize: 25,
        kor_id: kor_id.value,
      })
        .then((res) => {
          //先页码+1 然后写入数据 再关闭加载状态
          pageNo.value = pageNo.value + 1;
          warehouseList.value.push.apply(warehouseList.value, res.data);
          loading.value = false;
        })
        .catch((err) => {
          if (err.code === 210004) {
            finished.value = true;
            loading.value = false;
            return;
          }
          error.value = true;
          loading.value = false;
          utils.fail(err.err_msg);
        });
    };

    return {
      warehouseList,
      loading,
      error,
      finished,
      onLoad,
      pickItem,
      one_kor,
      showKorPicker,
      kor_id,
      kor_name,
      showModule,
    };
  },
};
</script>
